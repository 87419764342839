import './Footer.css'

import React from 'react'

const Footer = () => {

  return (
    <section id='footer' className='footer one-page-section'>
      <h1 className='footer-title'>Connect with us!</h1>
      <div className='icons-container'>
        <a href='https://facebook.com/newemperiumgames' target='_blank' rel="noopener noreferrer"><img src='images/svg/facebook.svg' alt="Facebook icon" className='footer-icon' /></a> 
        <a href='https://www.instagram.com/newemperiumgames/' target='_blank' rel="noopener noreferrer"><img src='images/svg/instagram.svg' alt="Instagram icon" className='footer-icon' /></a>
        <a href='mailto:support@newemperium.com' target='_blank' rel="noopener noreferrer"><img src='images/svg/gmail.svg' alt="E-mail icon" className='footer-icon' /></a>
      </div>
    </section>
  )
}

export default Footer
