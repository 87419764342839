import './App.css';

import Footer from './components/Footer';
import HotDice from './components/HotDice';
import HotWays from './components/HotWays';
import React from 'react';

function App() {
  return (
    <div className="wrapper">
        <HotWays />
        <HotDice />
        <Footer />
    </div>
  );
}

export default App;
